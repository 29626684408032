﻿import { RiseMap } from "./map";


google.maps.event.addDomListener(window, 'load', createLocationMap);

export function createLocationMap() {
    var xhr = null;

    var markers: { m: google.maps.Marker, id: number, data: any }[] = [];
    let map = document.getElementById('locationMap');
    let cdn = map.attributes['cdn'].value || '';
    let iconUrl = cdn + "/images/map-pin.png";
    let iconSelectedUrl = cdn + "/images/map_pin_selected.png";

    let locationMap = new RiseMap(map as Element)
    locationMap.map.setZoom(6);


    jQuery(document).ready(function ($: any) {
        getMembers();

        function getMembers() {
            if (xhr && xhr.state() === "pending") xhr.abort();
            xhr = $.ajax(map.attributes['members-endpoint'].value, { data: null });
            xhr.done(function (resp) {
                //console.log(resp);
                addOperators(resp);
            }).catch(function (err) {
                if (err.status === 0) return;//aborted
                console.log(err);
            });
        }
        function addOperators(operators: any) {
            operators.forEach((data, i) => {
                if (!data.Location) return;
                var marker = new google.maps.Marker({
                    icon: iconUrl,
                    map: locationMap.map,
                    title: data.OperatorName,
                    position: new google.maps.LatLng(data.Location.latlng.split(',')[0], data.Location.latlng.split(',')[1])
                });
                marker.addListener('click', function () {
                    locationMap.triggerEvent('MemberHighlighted', data);
                });
                markers.push({ m: marker, id: data.id, data: data });
            });

            locationMap.map.fitBounds(locationMap.getBoundsFromLocations(markers.map(x => x.m.getPosition())));
        }
        map.addEventListener("MemberHighlighted", function (e: CustomEvent) {
            var data = e.detail;
            // optional, speeds up future uses
            window.location.href = data.Url;
        });
    })
}