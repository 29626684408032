export class RiseMap {
    map!: google.maps.Map;
    private geocoder: google.maps.Geocoder;
    private _center = new google.maps.LatLng(51.509865, -0.118092);
    public defaultBounds = new google.maps.Circle({
        center: this._center,
        radius: 1
    }).getBounds();

    constructor(mapelement?: Element) {
        this.geocoder = new google.maps.Geocoder();

        if (mapelement) {
            this.map = new google.maps.Map(mapelement, {
                center: new google.maps.LatLng(51.509865, -0.118092),
                zoom: 10,
                noClear: true,
                clickableIcons: false,
                mapTypeControl: false,
                streetViewControl:false,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT
                },
                styles: theme
            });
        }
    }
    public geolocate$(): Promise<google.maps.LatLng> {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    resolve(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
                });
            } else {
                return resolve(this._center);
            }
        });
    };
    public getPlaceFromLocation$(location: google.maps.LatLng): Promise<google.maps.GeocoderResult> {
        return new Promise((resolve, reject) => {
            this.geocoder.geocode({ 'location': location }, function (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) {
                if (status == google.maps.GeocoderStatus.OK) {
                    resolve(results[0])
                } else {
                    reject('Geocode was not successful for the following reason: ' + status);
                }
            });
        });
    };
    public setCenter(latlng: google.maps.LatLngLiteral) {
        this._center = new google.maps.LatLng(latlng.lat, latlng.lng);
        return this;
    };
    public centerMap() {
        this.map.setCenter(this._center);
        return this;
    };

    public getBoundsFromLocations(locations: google.maps.LatLng[]): google.maps.LatLngBounds {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < locations.length; i++) {
            bounds.extend(locations[i]);
        }
        return bounds;
    }
    public triggerEvent(name: string, data: any) {
        var event = document.createEvent('CustomEvent');
        event.initCustomEvent(name, true, true, data);
        this.map.getDiv().dispatchEvent(event);
    }

}
let theme: google.maps.MapTypeStyle[] = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2aafe1"
            }
        ]
    }
    
]